import React, { useEffect, useState } from 'react';
import './App.css';
import Bear from './bear.png';

function App() {
  const [status, setStatus] = useState(0);
  useEffect(() => {
    const noButton = document.getElementById('no');
  if (noButton) {
    noButton.style.position = 'absolute'
    noButton.style.top = '65%';
    noButton.style.left= '55%';
  }
  },[]);
  const moveNo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const button = e.currentTarget;
    const buttonWidth = button.offsetWidth;
    const buttonHeight = button.offsetHeight;
    const maxX = document.documentElement.clientWidth - buttonWidth;
    const maxY = document.documentElement.clientHeight - buttonHeight;
    const randomX = Math.random() * maxX;
    const randomY = Math.random() * maxY;
    button.style.position = 'absolute';
    button.style.left = `${Math.max(0, Math.min(randomX, maxX))}px`;
    button.style.top = `${Math.max(0, Math.min(randomY, maxY))}px`;
  }

  return (
    <div className="App">
      {status === 0 &&
      <>
      <div className='bear'>
        <img src={Bear} style={{height:"128px"}} alt="Bear"/>
        <h1>Will you be my <strong>VALENTINE</strong>?</h1>
      </div>
      <button id='yes' onClick={() => {setStatus(1)}}>YES</button> 
      <button id='no' onClick={moveNo}>NO</button>
      </>
      }
      {status === 1 && 
        <div className='bear'>
        <img src='https://media1.tenor.com/m/rRZq40UyfRcAAAAC/ateez-atiny.gif' />
        <h1>Yeeeeey!</h1>
        <p>I'm soooooo HAPPY!!!</p>
        </div>
      }
    </div>
  );
}

export default App;
